// 默认热门首页运输商
export const defaultHotCarriers = [
  21051, 100002, 100003, 100001, 100062, 100005, 100331, 1151, 100040, 100030, 100007, 100012, 190271, 100295, 3011,
  190008
]

// 默认www首页热门运输商,目前注释的是www写的默认运输商列表存在部分我们carriers没有的内容
// const defaultWwwHotCarriers = [
//   190271, 100296, 1000003, 190172, 190008, 190002, 190120, 190208, 190011, 190200, 100011, 190086, 190018, 190074,
//   190072, 190085, 190047, 190644, 190199, 190274
// ]

// 特殊国家与语言热门运输商
export const carriersCountryLangMap = {
  US: {
    en: [
      21051, 100002, 100003, 100001, 100062, 100005, 100331, 1151, 100040, 100030, 100007, 100012, 190271, 100295, 3011,
      190008
    ]
  }
}

// 预留定义特殊合作配置,这里的结构和carriers运输商结构一致,type用于标识是否是特殊自定义配置合作商
export const cooperationPartner = {
  cjDropshipping: {
    type: 'customPartner',
    isBlank: true,
    key: 999001,
    _name: 'CJ Dropshipping',
    '_name_zh-cn': 'CJ Dropshipping',
    '_name_zh-hk': 'CJ Dropshipping',
    _showUrl: 'https://www.cjdropshipping.com/?token=5599f19d-0b2d-439b-bc37-c4f6861add5c',
    _url: 'https://www.cjdropshipping.com/?token=5599f19d-0b2d-439b-bc37-c4f6861add5c',
    _detail: '',
    '_detail_zh-cn': '',
    '_detail_zh-hk': ''
  }
}
// 默认国家热门运输商
export const defaultWwwHotCarriers = [
  21051, 100237, 100003, 3011, 190341, 100002, 100001, 190008, 11031, 190271, 9071, 190012, 6051
]

// 特殊国家热门运输商
export const carriersCountryMap = {
  us: [21051, 100003, 100002, 999001, 100001, 100308, 100049, 3011, 100783, 100254, 100221, 100242, 190094], // 美国
  de: [100001, 100007, 100005, 100018, 100002, 100003, 100308, 21051, 100004, 11031, 3011, 190008, 190012], // 德国
  it: [100005, 9071, 100001, 100004, 100002, 100003, 100458, 21051, 11031, 190625, 3011, 190008, 190012], // 意大利
  fr: [100001, 100002, 100003, 21051, 6051, 100005, 11031, 100004, 100273, 100685, 9021, 190094, 3011], // 法国
  es: [19181, 100001, 100003, 100002, 100005, 100438, 11031, 100004, 21051, 100175, 100685, 3011, 190625], // 西班牙
  pt: [100001, 16101, 100002, 100005, 100003, 100004, 11031, 190625, 21051, 9021, 6051, 100069, 100767], // 葡萄牙
  ru: [100157, 100001, 9021, 100003, 21051, 100002, 190625, 11031, 100069, 100012, 100685, 3011, 100005] // 俄罗斯
}

// platform 详情底部banner轮播
export const platformCarriersBanner = [
  21051, 100003, 100002, 100001, 100308, 100049, 3011, 100783, 100254, 100221, 100242, 190094, 190012
]

// 定义seo carriers 运输商不同的seo文章映射关系
export const carriersSeo = {
  seo: [
    100001, 100002, 100003, 100004, 100005, 100007, 100049, 100012, 100052, 100221, 100242, 100254, 100308, 100331,
    100340, 100398, 100441, 100685, 100782, 100783, 11031, 1151, 190008, 190012, 190094, 190199, 190271, 190625, 21051,
    3011, 3041
  ],
  normal: [
    100011, 100015, 100016, 100028, 100061, 100121, 100143, 100149, 100166, 100217, 100232, 100263, 100269, 100272,
    100302, 100305, 100328, 100332, 100333, 100343, 100362, 100395, 100427, 100429, 100439, 100440, 100442, 100443,
    100445, 100447, 100448, 100453, 100454, 100473, 100502, 100522, 100545, 100557, 100613, 100614, 100645, 100678,
    100679, 100684, 100690, 100695, 100733, 100736, 100742, 100743, 100751, 100752, 100758, 100784, 100805, 100817,
    100818, 100819, 100831, 100839, 100852, 100885, 100899, 100913, 100914, 100916, 100922, 100929, 100957, 100960,
    100964, 100971, 100972, 100982, 100992, 100995, 101017, 101023, 101024, 101025, 101027, 101028, 101036, 101037,
    191206, 191299
  ]
}

/**
 * page 多语言映射
 */
const PAGE_LOCAL_MAP = {
  en: 'Page',
  az: 'Səhifə',
  bg: 'Страница',
  cs: 'Stránka',
  da: 'Side',
  de: 'Seite',
  el: 'Σελίδα',
  es: 'Página',
  fi: 'Sivu',
  fr: 'Page',
  hu: 'Oldal',
  it: 'Pagina',
  ja: 'ページ',
  kk: 'Бет',
  ko: '페이지',
  lt: 'Puslapis',
  mk: 'Страница',
  nl: 'Pagina',
  no: 'Side',
  pl: 'Strona',
  pt: 'Página',
  ro: 'Pagina',
  ru: 'Страница',
  sk: 'Stránka',
  sl: 'Stran',
  sq: 'Faqe',
  sr: 'Страница',
  sv: 'Sida',
  th: 'หน้า',
  tr: 'Sayfa',
  uk: 'Сторінка',
  'zh-cn': '页面',
  'zh-hk': '頁面'
}

const carriersConfig = {
  carriersCountryMap, // 特殊热门国家运输商
  defaultWwwHotCarriers, // 特殊www首页热门国家运输商
  carriersCountryLangMap, // 特殊热门国家与语言运输商
  defaultHotCarriers, // 默认热门运输商
  cooperationPartner, // 特殊合作品牌配置
  carriersSeo, // 定义seo carriers 运输商不同的seo文章映射关系
  PAGE_LOCAL_MAP,
  platformCarriersBanner
}

export default carriersConfig
