import { useEffect, useState } from 'react'
import { dataSort } from 'src/utils/nav'

// 获取运输商轮播列表
export default function useCarrierBanner() {
  const [bannerList, setBanerList] = useState<any[]>([])

  function getBanerList() {
    try {
      const result = window.ResGCarrier?.filter((ele) => ele.key >= 17011 && ele.key <= 19286) || []
      setBanerList(dataSort(result, 'key'))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBanerList()
  }, [])

  return {
    bannerList
  }
}
