import carriersConfig from '@/config/carriers/carriersConfig'

let fs: typeof import('fs') | null = null
let path: typeof import('path') | null = null
let logger: typeof import('@/config/logger') | null = null

// const fs = require('fs')
// const path = require('path')
// const logger = require('@/config/logger')

if (typeof window === 'undefined') {
  fs = require('fs')
  path = require('path')
  logger = require('@/config/logger')
}

/**
 *@Description: 处理将运输商名字规范化字符串，转换为小写并去除多余空格,连续-改为一个，亚马逊运输商特殊转换，+加号去除，前端与node端处理逻辑一致
 *@param {String} carrierName 运输商名字字符串
 */
export const filterCarriersName = (carrierName) => {
  try {
    // 规范化字符串，转换为小写并去除多余空格
    const normalizeString = (str: string) => str?.toLowerCase().replace(/(^\s+)|(\s+$)|\s+/g, '-')
    carrierName = normalizeString(carrierName)
    // 匹配 amazon-shipping-(xx) 格式的字符串
    const regex = /amazon-shipping-\((\w+)\)/
    const match = carrierName?.match(regex)

    let newCarrierName = carrierName

    // 需要额外过滤的字符, amazon-shipping-(uk) ->  amazon-shipping , 其余amazon-shipping-(xx) -> amazon-shipping-xx
    const filtersArr = ['uk']

    if (match) {
      // 针对亚马逊，将括号中的内容去除
      const shippingCode = match[1]
      if (filtersArr.includes(shippingCode?.toLowerCase())) {
        newCarrierName = 'amazon-shipping'
      } else {
        newCarrierName = `amazon-shipping-${shippingCode}`
      }
    }

    // 将定义的元字符进行匹配，兜底兼容运输名异常
    if (/[.*+?^$#&,'":;<>@{}()|\[\]\\\/\+]/g.test(newCarrierName)) {
      newCarrierName = newCarrierName.replace(/[.*+?^$#&,'":;<>@{}()|\[\]\\\/\+]/g, '')
    }

    // 将多个连续的 - 号替换为一个 -
    newCarrierName = newCarrierName.replace(/-+/g, '-')

    return newCarrierName
  } catch (error) {
    console.log(error)
    return carrierName
  }
}

// 查询全运输商词条数据
export const toFilterSpace = (_str) => {
  try {
    // 这是问题词条的key，根据指定数组顺序返回过滤的数组
    return _str?.toLowerCase()?.replace(/(^\s+)|(\s+$)|\s+/g, '-')
  } catch (error) {
    console.log(error)
  }
}

/**
 *@Description: 锚点滚动当前80是咱们layout的margintop,66是top导航的高度，所以需要补这个差值，防止滚动不准确
 *@param {String} anchorId 需要滚动到对应节点的id名
 *@param {number} topAddNumber 需要参与到滚动数值额外滚动的值
 *@param {number} topDeleteNumber 需要参与到滚动数值减少滚动的值
 */
export const scrollToAnchor = ({
  anchorId,
  topAddNumber = 0,
  topDeleteNumber = 0
}: {
  anchorId: string
  topAddNumber?: number
  topDeleteNumber?: number
}) => {
  const anchorElement: HTMLElement | null = document.getElementById(anchorId)
  if (anchorElement) {
    const top = anchorElement.offsetTop + (80 - 66) + topAddNumber - topDeleteNumber
    window.scrollTo({
      top,
      behavior: 'smooth'
    })
  }
}

interface carriersDataDTO {
  _key: any
  _lang: any
  _type: string
}

/**
 *@Description: 针对特殊运输商返回特殊qa等数据
 *@param {String} _key 运输商的key
 *@param {any} _lang 对应语言内容
 *@param {String} _type 对应seo数据type [ normal(代表非供应商提供), seo(代表供应商提供) ]
 */
export const carriersData = ({ _key, _lang, _type = 'seo' }: carriersDataDTO): any => {
  // 匹配获取数据
  const DOMAIN_DATA_KV = {
    normal: {
      file: 'seo-carriers-normal-data'
    },
    seo: {
      file: 'seo-carriers-data'
    }
  }

  let carrierData = ''
  try {
    // 如果node读文件模块非node环境直接return空数据
    if (!fs || !path) {
      return carrierData
    }
    // 获取特殊seo运输商资源文本
    const FILE_PATH = path?.join(process.cwd(), `/config/carriers/${DOMAIN_DATA_KV[_type]?.['file']}/` + _key + '.json')
    if (!fs.existsSync(FILE_PATH)) {
      // 如果文件路径不存在直接返回空即可
      return carrierData
    }
    // const CARRIERS_DATA_JSON = require(`${FILE_PATH}`)
    const CARRIERS_DATA_JSON = JSON.parse(fs.readFileSync(FILE_PATH, 'utf-8'))

    if (Array.isArray(CARRIERS_DATA_JSON)) {
      CARRIERS_DATA_JSON.map((item) => {
        if (!!_key && item.carrierKey == _key && _lang == item.lang) {
          carrierData = item
        }
      })
    } else if (CARRIERS_DATA_JSON) {
      if (!!_key && CARRIERS_DATA_JSON.carrierKey == _key && _lang == CARRIERS_DATA_JSON.lang) {
        carrierData = CARRIERS_DATA_JSON
      }
    }
    logger?.info('carriers详情页面获取json成功：', { _key, _lang, _type })
  } catch (error: any) {
    logger?.error('carriers详情页面数据获取异常：', { _key, _lang, _type, message: error.message })
  }
  return carrierData
}

/**
 *@Description: 自定义正则替换方法
 *@param {String} text 需要替换的文本字符串
 *@param {RegExp} regex 正则字符串
 *@param {String} replaceText 替换匹配文本
 */
export const filterRegex = (text, regex, replaceText) => {
  if (regex) {
    try {
      new RegExp(regex)
      return text.replace(new RegExp(regex), replaceText ? replaceText : '')
    } catch (e) {
      return text
    }
  }
}

/**
 *@Description: 用户将某个数据上的某些自己想要找的值组合起来返回
 *@param {String} data 源数据
 *@param {String} keyArr 想要得到元数据上的key健
 */
export const arrKeyContacts = (data, keyArr) => {
  let _data = []
  if (Object.prototype.toString.call(keyArr) === '[object Array]') {
    keyArr.map((item) => {
      if (data[item] && data[item].length > 0) {
        _data = _data.concat(data[item])
      }
    })
  } else if (Object.prototype.toString.call(keyArr) === '[object String]') {
    if (data.keyArr && data.keyArr.length > 0) {
      _data = _data.concat(data.keyArr)
    }
  }
  return _data
}

/**
 *@Description: 根据指定数组列表将对应数据中存在的值按顺序优先前置于数组
 *@param {Array} resCarrier 目标被筛选排序的数组
 *@param {Array} hotCarrierKey 需要前置的数组列表
 */
export const sortCarrier = (resCarrier, hotCarrierKey) => {
  if (!resCarrier?.length) return []
  const result = new Array(hotCarrierKey.length)
  const filterResCarrier = resCarrier.filter((item) => {
    const hotIndex = hotCarrierKey.indexOf(item.key)
    if (hotIndex !== -1 && !result[hotIndex]) {
      result[hotIndex] = item
      return false
    }
    return true
  })
  return result.concat(filterResCarrier)
}

/**
 *@Description: 根据指定key数组对获取carrier对象
 *@param {Array} resCarrier 目标被筛选排序的数组
 *@param {Array} hotCarrierKey 需要前置的数组列表
 */
export const queryCarrierByKey = (resCarrier, hotCarrierKey) => {
  return hotCarrierKey.map((val) => {
    return resCarrier.find((item) => item.key === val) || {}
  })
}

/**
 *@Description: 获取按国家与语言区分的hot运输商
 *@param {String} country
 *@param {String} language
 */
export const getArrayByCountryAndLanguage = (country, language) => {
  if (!country || !language) {
    return carriersConfig.defaultHotCarriers
  }

  if (carriersConfig.carriersCountryLangMap[country] && carriersConfig.carriersCountryLangMap[country][language]) {
    return carriersConfig.carriersCountryLangMap[country][language]
  } else {
    return carriersConfig.defaultHotCarriers
  }
}

/**
 *@Description: 获取按国家区分的hot运输商
 *@param {String} country
 */
export const getArrayByCountry = (country) => {
  return carriersConfig.carriersCountryMap?.[country?.toLowerCase()] || carriersConfig.defaultWwwHotCarriers
}

/**
 *@Description: 用于将特殊数据进行千分位小数
 *@param {number} number
 */
export const numberFormat = (number) => {
  if (Number(number) == 0) {
    return number
  }
  const reg = /(\d)(?=(?:\d{3})+$)/g
  return number.toString().replace(reg, '$1,')
}

/**
 *@Description: 根据特殊定义查询运输商逻辑数据，返回运输商对应的文案标识
 *@param {KIV} obj 被查对象，通过value去匹配是否某key下存在该值，返回该key
 *@param {number} value 所需被查匹配值
 *@param {String} priorityKey 优先被查key
 *@return {String} seo：seo供应商与特殊定制文案 / normal：通用修改文案 / null：无特殊处理
 */
export const findKeyByValue = (obj: { [key: string]: number[] }, value: number, priorityKey?: string): string => {
  // 如果传入了优先的 key，先检查该 key
  if (priorityKey && obj[priorityKey]?.includes(value)) {
    return priorityKey
  }

  // 遍历对象的其他 key
  for (const key in obj) {
    if (key !== priorityKey && obj[key].includes(value)) {
      return key
    }
  }

  return 'null'
}
