// 首页-广告流量分配配置
export default function () {
  let webAdConfig = {
    items: {
      default: [
        {
          type: 'google',
          percentage: 0.95
        },
        {
          type: 'adengine',
          percentage: 0
        },
        {
          type: 'adpushup',
          percentage: 0.05
        }
      ],
      CN: [
        {
          type: 'google',
          percentage: 1
        }
      ]
    },
    conditions: [
      {
        country: ['CN'],
        item: 'CN'
      },
      {
        country: [],
        item: 'default'
      }
    ]
  }

  // 根据广告配置信息获取当前国家展示广告配置
  let conditions = webAdConfig.conditions
  let country = window.YQ.country || ''

  for (let i = 0; i < conditions.length; i++) {
    if (conditions[i].country.indexOf(country.toUpperCase()) > -1) {
      return webAdConfig.items[conditions[i].item] // 匹配country
    }
  }

  return webAdConfig.items[conditions[conditions.length - 1].item] // 都不匹配取default
}
