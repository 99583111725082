import { useEffect, useRef, useState } from 'react'
import { IKV } from 'src/types'
import { isAndroid, isIos, isWeixin } from 'src/utils/tools'

// 获取App下载地址
export default function useAppStore() {
  const browserInfoRef = useRef({} as IKV)
  const [isForeign, setIsForeign] = useState<boolean>(false)
  const [stores, setStores] = useState<Record<'icon' | 'link', string>[]>([])

  // 获取App Stores
  const getStores = (_isForeign: boolean) => {
    if (_isForeign) {
      // 中国大陆以外
      return [
        {
          icon: 'appstore_en.svg',
          link: getAppDownlodaUrl('ios')
        },
        {
          icon: 'googleplay_en.svg',
          link: getAppDownlodaUrl('google')
        },
        {
          icon: 'android_download_en.svg',
          link: '//download.17track.net/17track.apk'
        }
      ]
    } else {
      // 中国大陆
      return [
        {
          icon: 'appstore_cn.svg',
          link: getAppDownlodaUrl('ios')
        },
        {
          icon: 'chinastore_cn.svg',
          link: getAppDownlodaUrl('google')
        },
        {
          icon: 'android_download_cn.svg',
          link: '//download.17track.net/17track.apk'
        }
      ]
    }
  }

  // 获取是否为中国大陆以外
  const getIsForeign = () => {
    const country = window.YQ.country
    const lang = window.YQ.configs.lang

    return (country && country !== 'CN') || (!country && lang !== 'zh-cn')
  }

  // 获取浏览器信息
  const getBrowserInfo = () => {
    try {
      const isMobile = window.YQReloadMobile?.isMobile()
      const userAgent = navigator.userAgent.toLowerCase()
      let _ua: any = undefined
      let deviceInfo = undefined

      try {
        _ua = new window.UAParser()
        deviceInfo = _ua?.getDevice()
      } catch (e) {
        console.log(e)
      }
      return {
        userAgent: userAgent,
        deviceInfo: deviceInfo || {},
        isMobile: isMobile,
        isIos: isIos(userAgent),
        isAndroid: isAndroid(userAgent),
        // hash: url('#') || {},
        iosStore: '',
        anroidStore: ''
      }
    } catch (error) {
      return {}
    }
  }

  // 获取app下载地址
  const getAppDownlodaUrl = (source: string) => {
    const country = window.YQ.country
    const lang = window.YQ.configs.lang
    const _isWeixin = isWeixin(browserInfoRef.current.userAgent)
    let href = ''

    // 移动端的情况下
    if (browserInfoRef.current.isMobile) {
      // 微信打开，走应用宝应用商店
      if (_isWeixin) {
        href = 'https://a.app.qq.com/o/simple.jsp?pkgname=yqtrack.app'
      } else {
        // 区分IOS和安卓系统
        href = getMobilePlatformAppStore(source)
      }
    } else {
      // 平板设备
      if (browserInfoRef.current.deviceInfo.type === 'tablet') {
        href = getMobilePlatformAppStore(source)
      } else {
        // PC端打开
        // 如果没有指定是苹果应用商店下载，默认都按下载APK
        if (source === 'ios') {
          // IOS PC应用商店地址
          href = 'https://apps.apple.com/cn/app/id1004956012'
        } else if (source === 'google') {
          // 国家不是中国，跳到谷歌play应用商店
          if ((country && country !== 'CN') || (!country && lang !== 'zh-cn')) {
            href = 'https://play.google.com/store/apps/details?id=yqtrack.app'
          } else {
            // 国内应用商店
            href = '//download.17track.net/17track.apk'
          }
        } else {
          // 不明确用户选择的内容，不做任何处理
        }
      }
    }
    return href
  }

  // 获取移动端app下载地址
  const getMobilePlatformAppStore = (source: string) => {
    let _href = ''
    // 如果有指定系统，则以系统为准
    if (source) {
      if (source === 'ios') {
        _href = 'https://itunes.apple.com/app/id1004956012'
      } else if (source === 'google') {
        _href = 'market://details?id=yqtrack.app'
      } else {
        // 做容错处理，即下载APK应用
        _href = '//download.17track.net/17track.apk'
      }
    } else {
      if (browserInfoRef.current.isIos) {
        _href = 'https://itunes.apple.com/app/id1004956012'
      } else if (browserInfoRef.current.isAndroid) {
        // 唤起应用商店scheme
        _href = 'market://details?id=yqtrack.app'
      } else {
        // 做容错处理，即下载APK应用
        _href = '//download.17track.net/17track.apk'
      }
    }
    return _href
  }

  useEffect(() => {
    const _isForeign = getIsForeign()
    browserInfoRef.current = getBrowserInfo()
    setIsForeign(_isForeign)
    setStores(getStores(_isForeign))
  }, [])

  return {
    isForeign,
    stores
  }
}
